export default {
  post: (
    <svg width="24" viewBox="0 0 700 700" fill="currentColor">
      <g>
        <path d="m116.67 536.67h420c6.1875 0 12.121-2.4609 16.496-6.8359 4.3789-4.375 6.8359-10.309 6.8359-16.5v-233.33c0-8.3359-4.4492-16.039-11.668-20.207s-16.113-4.168-23.332 0-11.668 11.871-11.668 20.207v210h-373.33v-373.33h210c8.3359 0 16.039-4.4492 20.207-11.668s4.168-16.113 0-23.332-11.871-11.668-20.207-11.668h-233.33c-6.1914 0-12.125 2.457-16.5 6.8359-4.375 4.375-6.8359 10.309-6.8359 16.496v420c0 6.1914 2.4609 12.125 6.8359 16.5s10.309 6.8359 16.5 6.8359z" />
        <path d="m326.67 217.77v62.23c0 6.1875 2.457 12.125 6.832 16.5s10.312 6.832 16.5 6.832h62.207c6.1875 0 12.121-2.4609 16.496-6.8359l171.12-171.1c4.3828-4.3789 6.8438-10.316 6.8438-16.508 0-6.1953-2.4609-12.133-6.8438-16.512l-62.227-62.203c-4.3789-4.375-10.312-6.832-16.5-6.832-6.1836 0-12.121 2.457-16.496 6.832l-171.1 171.1c-4.375 4.375-6.8359 10.309-6.8359 16.496zm46.668 9.6836 147.77-147.79 29.238 29.238-147.8 147.77h-29.215z" />
      </g>
    </svg>
  ),

  feed: (
    <svg width="32" viewBox="0 0 24 24" fill="none">
      <g stroke-width="0"></g>
      <g stroke-linecap="round" stroke-linejoin="round"></g>
      <g>
        <path d="M5 12H18" stroke="currentColor" stroke-width="2" stroke-linecap="round"></path>
        <path d="M5 17H11" stroke="currentColor" stroke-width="2" stroke-linecap="round"></path>
        <path d="M5 7H15" stroke="currentColor" stroke-width="2" stroke-linecap="round"></path>
      </g>
    </svg>
  ),

  image: (
    <svg width="24" viewBox="0 0 24 24" fill="none">
      <g>
        <path
          d="M4.02693 18.329C4.18385 19.277 5.0075 20 6 20H18C19.1046 20 20 19.1046 20 18V14.1901M4.02693 18.329C4.00922 18.222 4 18.1121 4 18V6C4 4.89543 4.89543 4 6 4H18C19.1046 4 20 4.89543 20 6V14.1901M4.02693 18.329L7.84762 14.5083C8.52765 13.9133 9.52219 13.8482 10.274 14.3494L10.7832 14.6888C11.5078 15.1719 12.4619 15.1305 13.142 14.5865L15.7901 12.4679C16.4651 11.9279 17.4053 11.8856 18.1228 12.3484C18.2023 12.3997 18.2731 12.4632 18.34 12.5302L20 14.1901M11 9C11 10.1046 10.1046 11 9 11C7.89543 11 7 10.1046 7 9C7 7.89543 7.89543 7 9 7C10.1046 7 11 7.89543 11 9Z"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
      </g>
    </svg>
  ),

  settings: (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      width="25px"
      height="25.001px"
      viewBox="0 0 25 25.001"
      style="enable-background:new 0 0 25 25.001;"
      xmlSpace="preserve"
    >
      <g>
        <path
          fill="currentColor"
          d="M24.38,10.175l-2.231-0.268c-0.228-0.851-0.562-1.655-0.992-2.401l1.387-1.763c0.212-0.271,0.188-0.69-0.057-0.934 l-2.299-2.3c-0.242-0.243-0.662-0.269-0.934-0.057l-1.766,1.389c-0.743-0.43-1.547-0.764-2.396-0.99L14.825,0.62 C14.784,0.279,14.469,0,14.125,0h-3.252c-0.344,0-0.659,0.279-0.699,0.62L9.906,2.851c-0.85,0.227-1.655,0.562-2.398,0.991 L5.743,2.455c-0.27-0.212-0.69-0.187-0.933,0.056L2.51,4.812C2.268,5.054,2.243,5.474,2.456,5.746L3.842,7.51 c-0.43,0.744-0.764,1.549-0.991,2.4l-2.23,0.267C0.28,10.217,0,10.532,0,10.877v3.252c0,0.344,0.279,0.657,0.621,0.699l2.231,0.268 c0.228,0.848,0.561,1.652,0.991,2.396l-1.386,1.766c-0.211,0.271-0.187,0.69,0.057,0.934l2.296,2.301 c0.243,0.242,0.663,0.269,0.933,0.057l1.766-1.39c0.744,0.43,1.548,0.765,2.398,0.991l0.268,2.23 c0.041,0.342,0.355,0.62,0.699,0.62h3.252c0.345,0,0.659-0.278,0.699-0.62l0.268-2.23c0.851-0.228,1.655-0.562,2.398-0.991 l1.766,1.387c0.271,0.212,0.69,0.187,0.933-0.056l2.299-2.301c0.244-0.242,0.269-0.662,0.056-0.935l-1.388-1.764 c0.431-0.744,0.764-1.548,0.992-2.397l2.23-0.268C24.721,14.785,25,14.473,25,14.127v-3.252 C25.001,10.529,24.723,10.216,24.38,10.175z M12.501,18.75c-3.452,0-6.25-2.798-6.25-6.25s2.798-6.25,6.25-6.25 s6.25,2.798,6.25,6.25S15.954,18.75,12.501,18.75z"
        />
      </g>
    </svg>
  ),

  repost: (
    <svg fill="currentColor" width="24" viewBox="0 0 24 24">
      <g>
        <path
          d="M8.3,7.6l1.9-5.7L8.7,1.4l0,0L4.4,0L3.9,1.5l2.8,0.9C3.1,4.1,0.6,7.8,0.6,12c0,5.2,3.7,9.5,8.7,10.4v-1.4
	c-4.2-0.9-7.3-4.6-7.3-9c0-4,2.5-7.4,6-8.6L6.8,7.1L8.3,7.6z"
        />
        <path
          d="M22.1,12c0-5.2-3.7-9.5-8.7-10.4v1.4c4.2,0.9,7.3,4.6,7.3,9c0,4-2.5,7.4-6,8.6l1.2-3.7l-1.5-0.5l-1.9,5.7l1.5,0.5l0,0
	l4.2,1.4l0.5-1.5L16,21.6C19.6,19.9,22.1,16.2,22.1,12z"
        />
      </g>
    </svg>
  ),

  home: (
    <svg fill="currentColor" viewBox="0 0 24 24" width="24px" height="24px">
      <path
        d="M21.1,23.4h-5.3c-0.8,0-1.5-0.7-1.5-1.5v-5.3c0-0.6-0.5-1.2-1.2-1.2h-2.3c-0.6,0-1.2,0.5-1.2,1.2v5.3c0,0.8-0.7,1.5-1.5,1.5
	H2.9c-0.8,0-1.5-0.7-1.5-1.5V10.8c0-1.3,0.6-2.6,1.7-3.4l8.3-6.5c0.3-0.2,0.8-0.2,1.1,0l8.3,6.5c1.1,0.8,1.7,2.1,1.7,3.4v11.1
	C22.5,22.7,21.9,23.4,21.1,23.4z"
      />
    </svg>
  ),

  videoCall: (
    <svg enable-background="new 0 0 50 50" version="1.1" viewBox="0 0 50 50">
      <rect fill="none" style="height:24px;width:24px" />
      <polygon
        fill="none"
        points="49,14 36,21 36,29   49,36 "
        stroke="currentColor"
        stroke-linecap="round"
        stroke-miterlimit="10"
        stroke-width="4"
      />
      <path
        d="M36,36c0,2.209-1.791,4-4,4  H5c-2.209,0-4-1.791-4-4V14c0-2.209,1.791-4,4-4h27c2.209,0,4,1.791,4,4V36z"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-miterlimit="10"
        stroke-width="4"
      />
    </svg>
  ),

  //voiceCall: <svg enable-background="new 0 0 50 50" style="height:20px;width:20px" version="1.1" viewBox="0 0 50 50" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><rect fill="none" height="50" width="50"/><path d="M30.217,35.252c0,0,4.049-2.318,5.109-2.875  c1.057-0.559,2.152-0.7,2.817-0.294c1.007,0.616,9.463,6.241,10.175,6.739c0.712,0.499,1.055,1.924,0.076,3.32  c-0.975,1.396-5.473,6.916-7.379,6.857c-1.909-0.062-9.846-0.236-24.813-15.207C1.238,18.826,1.061,10.887,1,8.978  C0.939,7.07,6.459,2.571,7.855,1.595c1.398-0.975,2.825-0.608,3.321,0.078c0.564,0.781,6.124,9.21,6.736,10.176  c0.419,0.66,0.265,1.761-0.294,2.819c-0.556,1.06-2.874,5.109-2.874,5.109s1.634,2.787,7.16,8.312  C27.431,33.615,30.217,35.252,30.217,35.252z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="4"/></svg>,

  chat: (
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 486.736 486.736"
      style="enable-background:new 0 0 486.736 486.736;"
      width="24px"
      height="24px"
      fill="currentColor"
      stroke="#000000"
      stroke-width="0"
    >
      <path
        fill="currentColor"
        d="M481.883,61.238l-474.3,171.4c-8.8,3.2-10.3,15-2.6,20.2l70.9,48.4l321.8-169.7l-272.4,203.4v82.4c0,5.6,6.3,9,11,5.9 l60-39.8l59.1,40.3c5.4,3.7,12.8,2.1,16.3-3.5l214.5-353.7C487.983,63.638,485.083,60.038,481.883,61.238z"
      />
    </svg>
  ),

  circle: (
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 300 300"
      width="24px"
      fill="currentColor"
      height="24px"
      style="enable-background:new 0 0 300 300;"
    >
      <path d="M150,0C67.29,0,0,67.29,0,150s67.29,150,150,150s150-67.29,150-150S232.71,0,150,0z M150,270c-66.169,0-120-53.832-120-120 S83.831,30,150,30s120,53.832,120,120S216.168,270,150,270z" />
    </svg>
  ),

  folder: (
    <svg
      enable-background="new 0 0 512 512"
      height="24"
      viewBox="0 0 512 512"
      width="24"
      fill="currentColor"
    >
      <path d="m255.964 90c-13.696-18.207-35.478-30-59.964-30h-196v392h512v-362zm226.036 332h-452v-332h166c24.813 0 45 20.187 45 45v45h241zm0-272h-211v-15c0-5.137-.519-10.151-1.507-15h212.507z" />
    </svg>
  ),

  feed2: (
    <svg
      x="0px"
      y="0px"
      width="24px"
      height="24px"
      fill="currentColor"
      viewBox="0 0 124 124"
      style="enable-background:new 0 0 124 124;"
      xmlSpace="preserve"
    >
      <circle cx="20.3" cy="103.749" r="20" />
      <path d="M67,113.95c0,5.5,4.5,10,10,10s10-4.5,10-10c0-42.4-34.5-77-77-77c-5.5,0-10,4.5-10,10s4.5,10,10,10 C41.5,56.95,67,82.55,67,113.95z" />
      <path d="M114,123.95c5.5,0,10-4.5,10-10c0-62.8-51.1-113.9-113.9-113.9c-5.5,0-10,4.5-10,10s4.5,10,10,10 c51.8,0,93.9,42.1,93.9,93.9C104,119.45,108.4,123.95,114,123.95z" />
    </svg>
  ),

  store: (
    <svg viewBox="0 0 74 74" width="24" height="24" fill="currentColor">
      <g>
        <path d="M71,22H3a1,1,0,0,1-.908-1.419l6-13A1,1,0,0,1,9,7H65a1,1,0,0,1,.908.581l6,13A1,1,0,0,1,71,22ZM4.563,20H69.437L64.36,9H9.64Z" />
        <path d="M7.857,34A5.864,5.864,0,0,1,2,28.143V21a1,1,0,0,1,1-1h9.714a1,1,0,0,1,1,1v7.143A5.864,5.864,0,0,1,7.857,34ZM4,22v6.143a3.857,3.857,0,0,0,7.714,0V22Z" />
        <path d="M17.571,34a5.864,5.864,0,0,1-5.857-5.857V21a1,1,0,0,1,1-1h9.714a1,1,0,0,1,1,1v7.143A5.864,5.864,0,0,1,17.571,34ZM13.714,22v6.143a3.857,3.857,0,0,0,7.714,0V22Z" />
        <path d="M27.286,34a5.864,5.864,0,0,1-5.857-5.857V21a1,1,0,0,1,1-1h9.714a1,1,0,0,1,1,1v7.143A5.864,5.864,0,0,1,27.286,34ZM23.429,22v6.143a3.857,3.857,0,0,0,7.714,0V22Z" />
        <path d="M37,34a5.864,5.864,0,0,1-5.857-5.857V21a1,1,0,0,1,1-1h9.714a1,1,0,0,1,1,1v7.143A5.864,5.864,0,0,1,37,34ZM33.143,22v6.143a3.857,3.857,0,0,0,7.714,0V22Z" />
        <path d="M46.714,34a5.864,5.864,0,0,1-5.857-5.857V21a1,1,0,0,1,1-1h9.714a1,1,0,0,1,1,1v7.143A5.864,5.864,0,0,1,46.714,34ZM42.857,22v6.143a3.857,3.857,0,0,0,7.714,0V22Z" />
        <path d="M56.429,34a5.864,5.864,0,0,1-5.857-5.857V21a1,1,0,0,1,1-1h9.714a1,1,0,0,1,1,1v7.143A5.864,5.864,0,0,1,56.429,34ZM52.571,22v6.143a3.857,3.857,0,0,0,7.714,0V22Z" />
        <path d="M66.143,34a5.864,5.864,0,0,1-5.857-5.857V21a1,1,0,0,1,1-1H71a1,1,0,0,1,1,1v7.143A5.864,5.864,0,0,1,66.143,34ZM62.286,22v6.143a3.857,3.857,0,0,0,7.714,0V22Z" />
        <path d="M68,70H5.91a1,1,0,0,1-1-1V32h2V68H67V32h2V69A1,1,0,0,1,68,70Z" />
        <path d="M60,70H43a1,1,0,0,1-1-1V41a1,1,0,0,1,1-1H60a1,1,0,0,1,1,1V69A1,1,0,0,1,60,70ZM44,68H59V42H44Z" />
        <path d="M37,61.5H12a1,1,0,0,1-1-1v-20a1,1,0,0,1,1-1H37a1,1,0,0,1,1,1v20A1,1,0,0,1,37,61.5Zm-24-2H36v-18H13Z" />
      </g>
    </svg>
  ),

  close: (
    <svg height="25px" viewBox="0 0 329.26933 329" width="25px" fill="currentColor">
      <path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0" />
    </svg>
  ),

  play: (
    <svg
      x="0px"
      y="0px"
      height="25px"
      width="25px"
      viewBox="0 0 30.051 30.051"
      fill="currentColor"
      style="enable-background:new 0 0 30.051 30.051;"
    >
      <path d="M19.982,14.438l-6.24-4.536c-0.229-0.166-0.533-0.191-0.784-0.062c-0.253,0.128-0.411,0.388-0.411,0.669v9.069 c0,0.284,0.158,0.543,0.411,0.671c0.107,0.054,0.224,0.081,0.342,0.081c0.154,0,0.31-0.049,0.442-0.146l6.24-4.532 c0.197-0.145,0.312-0.369,0.312-0.607C20.295,14.803,20.177,14.58,19.982,14.438z" />
      <path d="M15.026,0.002C6.726,0.002,0,6.728,0,15.028c0,8.297,6.726,15.021,15.026,15.021c8.298,0,15.025-6.725,15.025-15.021 C30.052,6.728,23.324,0.002,15.026,0.002z M15.026,27.542c-6.912,0-12.516-5.601-12.516-12.514c0-6.91,5.604-12.518,12.516-12.518 c6.911,0,12.514,5.607,12.514,12.518C27.541,21.941,21.937,27.542,15.026,27.542z" />
    </svg>
  ),

  pause: (
    <svg
      enable-background="new 0 0 511.448 511.448"
      height="25px"
      width="25px"
      viewBox="0 0 511.448 511.448"
      fill="currentColor"
    >
      <path d="m436.508 74.94c-99.913-99.913-261.64-99.928-361.567 0-99.913 99.913-99.928 261.64 0 361.567 99.913 99.913 261.64 99.928 361.567 0 99.912-99.912 99.927-261.639 0-361.567zm-180.784 394.45c-117.816 0-213.667-95.851-213.667-213.667s95.851-213.666 213.667-213.666 213.666 95.851 213.666 213.667-95.85 213.666-213.666 213.666z" />
      <path d="m298.39 160.057c-11.598 0-21 9.402-21 21v149.333c0 11.598 9.402 21 21 21s21-9.402 21-21v-149.333c0-11.598-9.401-21-21-21z" />
      <path d="m213.057 160.057c-11.598 0-21 9.402-21 21v149.333c0 11.598 9.402 21 21 21s21-9.402 21-21v-149.333c0-11.598-9.401-21-21-21z" />
    </svg>
  ),

  user: (
    <svg
      fill="currentColor"
      width="24px"
      height="24px"
      viewBox="0 0 478.024 478.024"
      style="enable-background:new 0 0 478.024 478.024;"
    >
      <path
        d="M411.703,73.561c-45.117-47.093-107.542-73.67-172.76-73.55C107.145-0.155,0.166,106.554,0,238.353
        c-0.082,65.17,26.492,127.538,73.55,172.623c0.137,0.136,0.188,0.341,0.324,0.461c1.382,1.331,2.884,2.458,4.284,3.738
        c3.84,3.413,7.68,6.946,11.725,10.24c2.167,1.707,4.42,3.413,6.639,4.983c3.823,2.85,7.646,5.7,11.639,8.329
        c2.714,1.707,5.513,3.413,8.294,5.12c3.686,2.219,7.356,4.454,11.162,6.485c3.226,1.707,6.519,3.174,9.796,4.727
        c3.584,1.707,7.117,3.413,10.786,4.949c3.669,1.536,7.356,2.731,11.076,4.062s6.929,2.56,10.496,3.652
        c4.028,1.212,8.158,2.15,12.254,3.157c3.413,0.836,6.724,1.792,10.24,2.475c4.71,0.939,9.489,1.536,14.268,2.185
        c2.953,0.41,5.837,0.99,8.823,1.28c7.817,0.768,15.701,1.195,23.654,1.195s15.838-0.427,23.654-1.195
        c2.987-0.29,5.871-0.87,8.823-1.28c4.779-0.649,9.557-1.246,14.268-2.185c3.413-0.683,6.827-1.707,10.24-2.475
        c4.096-1.007,8.226-1.946,12.254-3.157c3.567-1.092,7.014-2.423,10.496-3.652c3.482-1.229,7.441-2.56,11.076-4.062
        s7.202-3.26,10.786-4.949c3.277-1.553,6.571-3.021,9.796-4.727c3.806-2.031,7.475-4.267,11.162-6.485
        c2.782-1.707,5.581-3.26,8.294-5.12c3.994-2.628,7.817-5.478,11.639-8.329c2.219-1.707,4.471-3.243,6.639-4.983
        c4.045-3.243,7.885-6.69,11.725-10.24c1.399-1.28,2.901-2.406,4.284-3.738c0.136-0.119,0.188-0.324,0.324-0.461
        C499.644,319.798,502.881,168.732,411.703,73.561z M373.344,393.107c-3.106,2.731-6.315,5.325-9.557,7.834
        c-1.911,1.468-3.823,2.918-5.786,4.318c-3.089,2.236-6.229,4.352-9.421,6.383c-2.321,1.485-4.693,2.918-7.083,4.318
        c-3.004,1.707-6.059,3.413-9.148,5.12c-2.731,1.399-5.513,2.714-8.311,4.011s-5.888,2.679-8.909,3.891
        c-3.021,1.212-6.229,2.355-9.387,3.413c-2.884,0.99-5.768,2.014-8.687,2.884c-3.413,1.024-6.98,1.86-10.513,2.714
        c-2.765,0.648-5.495,1.382-8.294,1.929c-4.045,0.785-8.175,1.331-12.322,1.894c-2.355,0.307-4.693,0.734-7.066,0.973
        c-6.554,0.631-13.193,1.007-19.9,1.007s-13.346-0.375-19.9-1.007c-2.372-0.239-4.71-0.666-7.066-0.973
        c-4.147-0.563-8.277-1.109-12.322-1.894c-2.799-0.546-5.53-1.28-8.294-1.929c-3.533-0.853-7.049-1.707-10.513-2.714
        c-2.918-0.87-5.803-1.894-8.687-2.884c-3.157-1.092-6.315-2.202-9.387-3.413c-3.072-1.212-5.973-2.543-8.909-3.891
        s-5.581-2.611-8.311-4.011c-3.089-1.604-6.144-3.294-9.148-5.12c-2.389-1.399-4.762-2.833-7.083-4.318
        c-3.191-2.031-6.332-4.147-9.421-6.383c-1.963-1.399-3.874-2.85-5.786-4.318c-3.243-2.509-6.451-5.12-9.557-7.834
        c-0.751-0.563-1.434-1.28-2.167-1.929c0.763-58.057,38.06-109.321,93.065-127.915c27.503,13.083,59.435,13.083,86.938,0
        c55.004,18.594,92.301,69.857,93.065,127.915C374.76,391.827,374.077,392.476,373.344,393.107z M179.43,136.849
        c18.479-32.864,60.1-44.525,92.964-26.046s44.525,60.1,26.046,92.964c-6.131,10.904-15.141,19.914-26.046,26.046
        c-0.085,0-0.188,0-0.29,0.102c-4.526,2.519-9.309,4.545-14.268,6.042c-0.887,0.256-1.707,0.597-2.645,0.819
        c-1.707,0.444-3.499,0.751-5.257,1.058c-3.31,0.579-6.659,0.915-10.018,1.007h-1.946c-3.359-0.092-6.708-0.428-10.018-1.007
        c-1.707-0.307-3.516-0.614-5.256-1.058c-0.905-0.222-1.707-0.563-2.645-0.819c-4.959-1.497-9.742-3.522-14.268-6.042l-0.307-0.102
        C172.612,211.334,160.951,169.713,179.43,136.849z M405.753,357.336L405.753,357.336c-10.952-51.083-44.59-94.39-91.375-117.64
        c38.245-41.661,35.475-106.438-6.186-144.683c-41.661-38.245-106.438-35.475-144.683,6.186
        c-35.954,39.166-35.954,99.332,0,138.497c-46.785,23.251-80.423,66.557-91.375,117.64C6.69,265.153,28.366,137.371,120.549,71.927
        s219.965-43.768,285.409,48.415c24.601,34.653,37.807,76.104,37.786,118.602C443.744,281.405,430.46,322.802,405.753,357.336z"
      />
    </svg>
  ),
  group: (
    <svg
      x="0px"
      y="0px"
      width="24px"
      height="24px"
      viewBox="0 0 512 512"
      style="enable-background:new 0 0 512 512;"
      fill="currentColor"
    >
      <g>
        <g>
          <path
            d="M331.109,31.459c-12.983,0-25.378,2.544-36.743,7.121c20.261,25.057,32.417,56.924,32.417,91.583
          s-12.157,66.525-32.417,91.583c11.365,4.577,23.76,7.121,36.743,7.121c54.426,0,98.704-44.277,98.704-98.703
          S385.535,31.459,331.109,31.459z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M331.109,276.055c-3.682,0-7.358,0.116-11.026,0.341c54.027,41.747,88.888,107.149,88.888,180.55
          c0,8.271-1.439,16.209-4.058,23.594h83.493c13.03,0,23.594-10.564,23.594-23.594C512,357.203,430.852,276.055,331.109,276.055z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M256,66.228c-18.119-21.252-45.058-34.769-75.109-34.769c-54.426,0-98.704,44.277-98.704,98.704
          s44.277,98.703,98.704,98.703c30.052,0,56.99-13.516,75.109-34.769c14.695-17.238,23.594-39.558,23.594-63.935
          S270.695,83.466,256,66.228z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M255.964,292.386c-22.88-10.479-48.304-16.331-75.073-16.331C81.148,276.055,0,357.203,0,456.946
          c0,13.03,10.564,23.594,23.594,23.594h314.593c0.002,0,0.002,0,0.002,0c13.03-0.002,23.593-10.566,23.593-23.594
          C361.782,383.969,318.341,320.956,255.964,292.386z"
          />
        </g>
      </g>
    </svg>
  ),
  heartEmpty: (
    <svg width="24" viewBox="0 0 24 24" fill="currentColor">
      <path
        d="M12,22.7c-0.3,0-0.7-0.1-0.9-0.3c-1-0.8-1.9-1.6-2.7-2.3l0,0c-2.4-2.1-4.5-3.8-6-5.6C0.8,12.4,0,10.6,0,8.5
	c0-2,0.7-3.8,1.9-5.1c1.2-1.3,2.9-2.1,4.8-2.1c1.4,0,2.7,0.4,3.8,1.3c0.6,0.4,1.1,1,1.5,1.6c0.5-0.6,1-1.2,1.5-1.6
	c1.1-0.9,2.4-1.3,3.8-1.3c1.9,0,3.6,0.7,4.8,2.1C23.3,4.7,24,6.6,24,8.5c0,2-0.8,3.9-2.4,5.8c-1.5,1.8-3.5,3.5-6,5.6
	c-0.8,0.7-1.8,1.5-2.7,2.4C12.7,22.5,12.3,22.7,12,22.7z M6.7,2.7c-1.5,0-2.8,0.6-3.8,1.6c-1,1.1-1.5,2.6-1.5,4.2
	c0,1.7,0.6,3.2,2.1,5c1.4,1.7,3.4,3.4,5.8,5.4l0,0c0.8,0.7,1.8,1.5,2.7,2.4c1-0.9,1.9-1.7,2.8-2.4c2.4-2,4.4-3.8,5.8-5.4
	c1.4-1.7,2.1-3.2,2.1-5c0-1.6-0.5-3.1-1.5-4.2c-1-1.1-2.3-1.6-3.8-1.6c-1.1,0-2,0.3-2.9,1c-0.8,0.6-1.3,1.3-1.6,1.9
	C12.6,5.9,12.3,6,12,6s-0.6-0.2-0.8-0.4c-0.3-0.5-0.9-1.3-1.6-1.9C8.8,3.1,7.8,2.7,6.7,2.7z"
      />
    </svg>
  ),
  heartFull: (
    <svg width="24" viewBox="0 -28 512.00002 512">
      <path
        fill="currentColor"
        d="m471.382812 44.578125c-26.503906-28.746094-62.871093-44.578125-102.410156-44.578125-29.554687 0-56.621094 9.34375-80.449218 27.769531-12.023438 9.300781-22.917969 20.679688-32.523438 33.960938-9.601562-13.277344-20.5-24.660157-32.527344-33.960938-23.824218-18.425781-50.890625-27.769531-80.445312-27.769531-39.539063 0-75.910156 15.832031-102.414063 44.578125-26.1875 28.410156-40.613281 67.222656-40.613281 109.292969 0 43.300781 16.136719 82.9375 50.78125 124.742187 30.992188 37.394531 75.535156 75.355469 127.117188 119.3125 17.613281 15.011719 37.578124 32.027344 58.308593 50.152344 5.476563 4.796875 12.503907 7.4375 19.792969 7.4375 7.285156 0 14.316406-2.640625 19.785156-7.429687 20.730469-18.128907 40.707032-35.152344 58.328125-50.171876 51.574219-43.949218 96.117188-81.90625 127.109375-119.304687 34.644532-41.800781 50.777344-81.4375 50.777344-124.742187 0-42.066407-14.425781-80.878907-40.617188-109.289063zm0 0"
      />
    </svg>
  ),
  newFollower: (
    <svg width="24" viewBox="0 0 24 24" fill="currentColor">
      <path
        d="M12,6c2.8,0,5.1,2.3,5.1,5.1s-2.3,5.1-5.1,5.1s-5.1-2.3-5.1-5.1S9.2,6,12,6z M17.5,4.6h1.8v1.8c0,0.5,0.4,0.9,0.9,0.9
	c0.5,0,0.9-0.4,0.9-0.9V4.6h1.8c0.5,0,0.9-0.4,0.9-0.9s-0.4-0.9-0.9-0.9h-1.8V0.9c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9v1.8
	h-1.8c-0.5,0-0.9,0.4-0.9,0.9S17,4.6,17.5,4.6z M22.8,9.3c-0.5,0.1-0.8,0.5-0.8,1c0,0,0,0,0,0c0.1,0.5,0.1,1.1,0.1,1.6
	c0,2.5-0.9,4.9-2.6,6.7c-0.7-1.1-1.7-2-2.8-2.7c-2.7,2.6-6.9,2.6-9.5,0c-1.1,0.7-2.1,1.6-2.8,2.7C0.7,14.5,1.1,8.1,5.3,4.4
	C7.1,2.8,9.5,1.8,12,1.8c0.5,0,1.1,0,1.6,0.1c0.5,0.1,1-0.3,1-0.8c0.1-0.5-0.3-0.9-0.8-1C13.3,0,12.6,0,12,0C5.4,0,0,5.4,0,12
	c0,3.4,1.4,6.6,3.9,8.8L4,21c4.5,4,11.4,4,15.9,0l0.2-0.1c2.5-2.3,3.9-5.5,3.9-8.9c0-0.6,0-1.3-0.2-1.9C23.8,9.6,23.3,9.3,22.8,9.3
	C22.8,9.3,22.8,9.3,22.8,9.3z"
      />
    </svg>
  ),
  menu: (
    <svg
      fill="currentColor"
      x="0px"
      y="0px"
      viewBox="0 0 384 384"
      width="24px"
      height="24px"
      enable-background="new 0 0 384 384;"
    >
      <g>
        <rect x="0" y="277.333" width="384" height="42.667" />
        <rect x="0" y="170.667" width="384" height="42.667" />
        <rect x="0" y="64" width="384" height="42.667" />
      </g>
    </svg>
  ),
  language: (
    <svg
      width="14"
      height="14"
      style="margin-bottom: -1px; enable-background:new 0 0 469.333 469.333;"
      x="0px"
      y="0px"
      viewBox="0 0 469.333 469.333"
    >
      <path
        fill="currentColor"
        d="M253.227,300.267L253.227,300.267L199.04,246.72l0.64-0.64c37.12-41.387,63.573-88.96,79.147-139.307h62.507V64H192 V21.333h-42.667V64H0v42.453h238.293c-14.4,41.173-36.907,80.213-67.627,114.347c-19.84-22.08-36.267-46.08-49.28-71.467H78.72 c15.573,34.773,36.907,67.627,63.573,97.28l-108.48,107.2L64,384l106.667-106.667l66.347,66.347L253.227,300.267z"
      />
      <path
        fill="currentColor"
        d="M373.333,192h-42.667l-96,256h42.667l24-64h101.333l24,64h42.667L373.333,192z M317.333,341.333L352,248.853 l34.667,92.48H317.333z"
      />
    </svg>
  ),
  search: (
    <svg fill="currentColor" width="24px" height="24px" viewBox="0 0 24 24">
      <path
        d="M23.5,22.3L18,16.8H18c3.6-4.2,3.1-10.6-1.2-14.2S6.2-0.4,2.6,3.8S-0.4,14.4,3.8,18c3.8,3.2,9.3,3.2,13,0c0,0,0,0,0,0.1
		l5.4,5.4c0.3,0.3,0.9,0.3,1.2,0c0,0,0,0,0,0C23.8,23.2,23.8,22.7,23.5,22.3C23.5,22.3,23.5,22.3,23.5,22.3z M10.3,19
		c-4.8,0-8.7-3.9-8.7-8.7s3.9-8.7,8.7-8.7S19,5.5,19,10.3S15.1,19,10.3,19z"
      />
    </svg>
  ),
  global: (
    <svg width="24px" height="24px" viewBox="0 0 24 24" fill="currentColor">
      <g>
        <path
          d="M12,23.9C5.4,23.9,0.1,18.6,0.1,12C0.1,5.4,5.4,0,12,0C18.6,0,24,5.4,24,12C24,18.6,18.6,23.9,12,23.9z M12,1.4
		C6.2,1.4,1.5,6.2,1.5,12S6.2,22.5,12,22.5S22.6,17.8,22.6,12S17.8,1.4,12,1.4z"
        />
        <path
          d="M2.8,19.2c-0.2,0-0.5-0.1-0.6-0.3c-0.2-0.3-0.1-0.8,0.2-1L3.8,17C3.9,17,4,16.9,4,16.8l0-4.2c0-0.3,0.1-0.6,0.3-0.9
		l2.3-3.6c0.2-0.4,0.6-0.6,1-0.7c0.4-0.1,0.9,0,1.2,0.2l2.4,1.7c0,0,0.1,0.1,0.2,0l3.7-0.5c0.1,0,0.1,0,0.1-0.1l2.6-3
		c0,0,0.1-0.1,0.1-0.2l-0.1-2.8c0-0.4,0.3-0.7,0.7-0.7c0.4,0,0.7,0.3,0.7,0.7l0.1,2.8c0,0.4-0.1,0.8-0.4,1.1l-2.6,3
		c-0.3,0.3-0.6,0.5-1,0.6l-3.7,0.5c-0.4,0-0.8-0.1-1.2-0.3L8.1,8.8c-0.1-0.1-0.2,0-0.2,0c0,0-0.1,0-0.2,0.1l-2.3,3.6
		c0,0,0,0.1,0,0.1l0,4.2c0,0.6-0.3,1.1-0.8,1.4l-1.4,0.8C3.1,19.1,3,19.2,2.8,19.2z"
        />
        <path
          d="M18.9,21.6c-0.2,0-0.4-0.1-0.5-0.2l-1.3-1.3c0,0-0.1-0.1-0.1-0.1l-2.5-0.7c-0.8-0.2-1.3-1-1.2-1.8l0.3-1.9
		c0.1-0.6,0.5-1,1-1.3l3.6-1.5c0.6-0.2,1.3-0.1,1.7,0.3l2.9,2.7c0.3,0.3,0.3,0.7,0,1c-0.3,0.3-0.7,0.3-1,0L19,14.2
		c-0.1-0.1-0.2-0.1-0.3,0l-3.6,1.5c-0.1,0-0.1,0.1-0.2,0.2l-0.3,1.9c0,0.1,0.1,0.2,0.2,0.3l2.5,0.7c0.3,0.1,0.5,0.2,0.7,0.4l1.3,1.3
		c0.3,0.3,0.3,0.7,0,1C19.3,21.5,19.1,21.6,18.9,21.6z"
        />
      </g>
    </svg>
  ),
  backArrow: (
    <svg
      fill="currentColor"
      x="0px"
      y="0px"
      width="24px"
      height="24px"
      viewBox="0 0 447.243 447.243"
      style="enable-background:new 0 0 447.243 447.243;"
      xmlSpace="preserve"
    >
      <g>
        <path
          d="M420.361,192.229c-1.83-0.297-3.682-0.434-5.535-0.41H99.305l6.88-3.2c6.725-3.183,12.843-7.515,18.08-12.8l88.48-88.48
        c11.653-11.124,13.611-29.019,4.64-42.4c-10.441-14.259-30.464-17.355-44.724-6.914c-1.152,0.844-2.247,1.764-3.276,2.754
        l-160,160C-3.119,213.269-3.13,233.53,9.36,246.034c0.008,0.008,0.017,0.017,0.025,0.025l160,160
        c12.514,12.479,32.775,12.451,45.255-0.063c0.982-0.985,1.899-2.033,2.745-3.137c8.971-13.381,7.013-31.276-4.64-42.4
        l-88.32-88.64c-4.695-4.7-10.093-8.641-16-11.68l-9.6-4.32h314.24c16.347,0.607,30.689-10.812,33.76-26.88
        C449.654,211.494,437.806,195.059,420.361,192.229z"
        />
      </g>
    </svg>
  ),
  info: (
    <svg fill="currentColor" viewBox="0 0 24 24" width="24px" height="24px">
      <path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 7 L 11 9 L 13 9 L 13 7 L 11 7 z M 11 11 L 11 17 L 13 17 L 13 11 L 11 11 z" />
    </svg>
  ),
  network: (
    <svg
      fill="currentColor"
      enable-background="new 0 0 512 512"
      height="16px"
      width="16px"
      viewBox="0 0 512 512"
    >
      <g>
        <g>
          <g>
            <path d="m256 150.5c-41.353 0-75-33.647-75-75s33.647-75 75-75 75 33.647 75 75-33.647 75-75 75z" />
          </g>
          <g>
            <path d="m10.026 429c-20.669-35.815-8.35-81.768 27.466-102.451 36.551-21.085 82.083-7.806 102.451 27.451 20.722 35.87 8.44 81.717-27.451 102.451-35.96 20.737-81.757 8.396-102.466-27.451z" />
          </g>
          <g>
            <path d="m399.508 456.451c-35.867-20.721-48.185-66.561-27.451-102.451 20.367-35.256 65.898-48.537 102.451-27.451 35.815 20.684 48.135 66.636 27.466 102.451-20.683 35.802-66.455 48.218-102.466 27.451z" />
          </g>
        </g>
        <g>
          <path d="m61.293 275.587-29.941-1.641c3.896-70.957 41.807-136.641 101.396-175.723l16.465 25.078c-51.665 33.883-84.522 90.821-87.92 152.286z" />
        </g>
        <g>
          <path d="m450.707 275.587c-3.398-61.465-36.255-118.403-87.92-152.285l16.465-25.078c59.59 39.082 97.5 104.766 101.396 175.723z" />
        </g>
        <g>
          <path d="m256 511.5c-35.684 0-69.8-8.115-101.426-24.097l13.535-26.777c54.785 27.715 120.996 27.715 175.781 0l13.535 26.777c-31.625 15.982-65.741 24.097-101.425 24.097z" />
        </g>
      </g>
    </svg>
  ),
  QRcode: (
    <svg
      fill="currentColor"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 122.88 122.7"
      style="enable-background:new 0 0 122.88 122.7"
      width="24px"
      height="24px"
    >
      <g>
        <path
          class="st0"
          d="M0.18,0h44.63v44.45H0.18V0L0.18,0z M111.5,111.5h11.38v11.2H111.5V111.5L111.5,111.5z M89.63,111.48h11.38 v10.67H89.63h-0.01H78.25v-21.82h11.02V89.27h11.21V67.22h11.38v10.84h10.84v11.2h-10.84v11.2h-11.21h-0.17H89.63V111.48 L89.63,111.48z M55.84,89.09h11.02v-11.2H56.2v-11.2h10.66v-11.2H56.02v11.2H44.63v-11.2h11.2V22.23h11.38v33.25h11.02v11.2h10.84 v-11.2h11.38v11.2H89.63v11.2H78.25v22.05H67.22v22.23H55.84V89.09L55.84,89.09z M111.31,55.48h11.38v11.2h-11.38V55.48 L111.31,55.48z M22.41,55.48h11.38v11.2H22.41V55.48L22.41,55.48z M0.18,55.48h11.38v11.2H0.18V55.48L0.18,55.48z M55.84,0h11.38 v11.2H55.84V0L55.84,0z M0,78.06h44.63v44.45H0V78.06L0,78.06z M10.84,88.86h22.95v22.86H10.84V88.86L10.84,88.86z M78.06,0h44.63 v44.45H78.06V0L78.06,0z M88.91,10.8h22.95v22.86H88.91V10.8L88.91,10.8z M11.02,10.8h22.95v22.86H11.02V10.8L11.02,10.8z"
        />
      </g>
    </svg>
  ),
  checkmark: (
    <svg height="14" width="14" x="0px" y="0px" viewBox="0 0 191.667 191.667">
      <path
        fill="currentColor"
        d="M95.833,0C42.991,0,0,42.99,0,95.833s42.991,95.834,95.833,95.834s95.833-42.991,95.833-95.834S148.676,0,95.833,0z M150.862,79.646l-60.207,60.207c-2.56,2.56-5.963,3.969-9.583,3.969c-3.62,0-7.023-1.409-9.583-3.969l-30.685-30.685 c-2.56-2.56-3.97-5.963-3.97-9.583c0-3.621,1.41-7.024,3.97-9.584c2.559-2.56,5.962-3.97,9.583-3.97c3.62,0,7.024,1.41,9.583,3.971 l21.101,21.1l50.623-50.623c2.56-2.56,5.963-3.969,9.583-3.969c3.62,0,7.023,1.409,9.583,3.969 C156.146,65.765,156.146,74.362,150.862,79.646z"
      />
    </svg>
  ),
};
